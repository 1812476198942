import dayjs from "dayjs";
import {
  getFromUserStorage,
  saveToUserStorage,
  removeFromUserStorage,
} from "~/helpers/userStorage";

const REFRESH_TOKEN_KEY = "hungryhub_refresh_token";
const ACCESS_TOKEN_KEY = "hungryhub_access_token";
const ACCESS_TOKEN_EXPIRED_IN_KEY = "hungryhub_access_token_expired_in";
const VOUCHER_PURCHASED_KEY = "hungryhub_voucher_purchased";
const REFRESH_TOKEN_MINIMUM_AGE = 2; // 2 days
const COOKIES_DURATION = 365; // days
// wil remove this code after few week, to make sure old cookie removed
const COOKIE_DOMAIN = ".hungryhub.com";

// refresh token
function storageGetRefreshToken() {
  return (
    getFromUserStorage({ key: REFRESH_TOKEN_KEY, type: "localstorage" }) || ""
  );
}

function storageRemoveRefreshToken() {
  // Rollbar.debug("Removing auth cookie", { name: "refresh token" });
  removeFromUserStorage({ key: REFRESH_TOKEN_KEY, type: "localstorage" });
  // wil remove this code after few week, to make sure old cookie removed
  removeFromUserStorage({
    key: REFRESH_TOKEN_KEY,
    type: "localstorage",
    cookieOption: { domain: COOKIE_DOMAIN },
  });
}

function storageSetRefreshToken(token: string) {
  saveToUserStorage({
    key: REFRESH_TOKEN_KEY,
    val: token,
    type: "localstorage",
    cookieOption: {
      expires: COOKIES_DURATION,
    },
  });
}

// access token
function storageGetAccessToken() {
  return (
    getFromUserStorage({ key: ACCESS_TOKEN_KEY, type: "localstorage" }) || ""
  );
}

function storageRemoveAccessToken() {
  removeFromUserStorage({ key: ACCESS_TOKEN_KEY, type: "localstorage" });
  // wil remove this code after few week, to make sure old cookie removed
  removeFromUserStorage({
    key: ACCESS_TOKEN_KEY,
    type: "localstorage",
    cookieOption: { domain: COOKIE_DOMAIN },
  });
}

function storageSetAccessToken(token: string) {
  saveToUserStorage({
    key: ACCESS_TOKEN_KEY,
    val: token,
    type: "localstorage",
    cookieOption: {
      expires: COOKIES_DURATION,
    },
  });
}

//  access token expired in
function storageGetAccessTokenExpiredIn() {
  return (
    getFromUserStorage({
      key: ACCESS_TOKEN_EXPIRED_IN_KEY,
      type: "localstorage",
    }) || ""
  );
}

// set voucher purchaed
function storageSetVoucherPurchased(purchaseId: string) {
  saveToUserStorage({
    key: VOUCHER_PURCHASED_KEY,
    val: purchaseId,
    type: "localstorage",
  });
}

// get voucher purchased
function storageGetVoucherPurchased() {
  return (
    getFromUserStorage({ key: VOUCHER_PURCHASED_KEY, type: "localstorage" }) ||
    ""
  );
}

function storageRemoveAccessTokenExpiredIn() {
  removeFromUserStorage({
    key: ACCESS_TOKEN_EXPIRED_IN_KEY,
    type: "localstorage",
  });
  // wil remove this code after few week, to make sure old cookie removed
  removeFromUserStorage({
    key: ACCESS_TOKEN_EXPIRED_IN_KEY,
    type: "localstorage",
    cookieOption: { domain: COOKIE_DOMAIN },
  });
}

function storageSetAccessTokenExpiredIn(expiredInSecond = 0) {
  const parsedExpiredIn = dayjs().add(expiredInSecond, "second").toISOString();

  saveToUserStorage({
    key: ACCESS_TOKEN_EXPIRED_IN_KEY,
    val: parsedExpiredIn,
    type: "localstorage",
    cookieOption: {
      expires: COOKIES_DURATION,
    },
  });
}

function canRefreshToken() {
  const expiredIn = storageGetAccessTokenExpiredIn() || false;
  const refreshToken = storageGetRefreshToken() || false;
  return !!(expiredIn && refreshToken);
}

function shouldRefreshToken() {
  const today = dayjs().startOf("day");
  const expiredIn = storageGetAccessTokenExpiredIn() || false;
  const differentTodayWithExpiredIn = dayjs(expiredIn.data)
    .startOf("day")
    .diff(today, "day");
  if (differentTodayWithExpiredIn < REFRESH_TOKEN_MINIMUM_AGE) {
    return true;
  }
  return false;
}

function shouldLoginAgain() {
  let isShouldLoginAgain = false;
  const accessToken = storageGetAccessToken();
  const expiredIn = storageGetAccessTokenExpiredIn();
  const refreshToken = storageGetRefreshToken();
  const anyAccessToken = !!accessToken;
  const anyExpiredIn = !!expiredIn;
  const anyRefreshToken = !!refreshToken;
  if (!anyAccessToken || !anyExpiredIn || !anyRefreshToken) {
    // const payload = { accessToken, expiredIn, refreshToken };
    // const message = "User need to login again";
    // Rollbar.warning(message, payload);
    isShouldLoginAgain = true;
  }
  return isShouldLoginAgain;
}

export {
  // expired in
  storageSetAccessTokenExpiredIn,
  storageRemoveAccessTokenExpiredIn,
  storageGetAccessTokenExpiredIn,
  // access token
  storageSetAccessToken,
  storageRemoveAccessToken,
  storageGetAccessToken,
  // refresh token
  storageSetRefreshToken,
  storageRemoveRefreshToken,
  storageGetRefreshToken,
  shouldRefreshToken,
  shouldLoginAgain,
  canRefreshToken,
  // voucher purchased
  storageSetVoucherPurchased,
  storageGetVoucherPurchased,
};
